import React, { useState } from 'react';
import './App.css';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/adventure_time.css';

function App() {
  const [inputText, setInputText] = useState('');
  const [apiResponse, setApiResponse] = useState('');
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleButtonClick = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://api-f3aad86d-0834-49cf-83fe-aea41a6d559b-demo.openshield.dev/openai/v1/chat/completions`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer 5166d379-8770-405a-9c65-48921471b484`,
          },
          body: JSON.stringify({
            model: 'gpt-4',
            messages: [
              {
                role: 'system',
                content: 'You are a helpful assistant.',
              },
              {
                role: 'user',
                content: `${inputText}`,
                multi_content: [],
                name: 'example',
                function_call: null,
                tool_calls: [],
                tool_call_id: '',
              },
            ],
          }),
        }
      );

      const data = await response.json();
      setApiResponse(data); // Assuming the API sends a field called 'message'
    } catch (error) {
      console.error('Error fetching data:', error);
      setApiResponse('Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='App'>
      <h1>Content Safety API</h1>
      <input
        type='text'
        value={inputText}
        onChange={handleInputChange}
        placeholder='Enter a prompt here'
      />
      <button onClick={handleButtonClick} disabled={loading}>
        {loading ? 'Loading...' : 'Submit'}
      </button>
      <div>
        <h2>API Response:</h2>
        {typeof apiResponse === 'object' ? (
          <JSONPretty data={apiResponse} />
        ) : (
          <p>{apiResponse}</p>
        )}
      </div>
    </div>
  );
}

export default App;
